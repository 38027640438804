import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

const Notfound = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">404</h2>
                  <p className="gr-text-8 mb-5">
                    Unfortunately we could not find the requested page
                  </p>
                  <h3 className="gr-text-7 mb-8">
                    <a href="https://trustify.ch/">Back to startpage</a> |
                    <a href="https://app.trustify.ch/"> Back to dashboard</a>
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Notfound;
